@import "../../styles/variables";
.withdraw-page{
  display: flex;
  flex-direction: column;
  gap: 40px;
  .table {
    .raw-data {
      display: none;
    }
  }

  h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    margin-bottom: 22px;

  }
  .payment-system {
    display: flex;

    gap: 8px;

  }

  .status {
    &.success {
      color: $forest-green;
    }
    &.fail {
      color: $crimson-red;
    }
  }

  .comment {


  }
  .comment-is-emty{
    cursor: pointer;
    color: $soft-gray;
  }
}

.dark {
  .withdraw-wrapper {
    background: $dark-bg;

    .withdraw-page {

      h2 {
        color: white;
      }
    }

    .tables-container {
      background: linear-gradient(90deg, #0F101D 0%, #1B2133 100.03%);
      border-radius: 16px;
      padding: 32px;
      .table {

        thead {
          color: rgba(157, 157, 161, 1) ;

        }
        tbody{
          color: white;
          td{
            border-bottom: 1px solid rgba(32, 37, 54, 1);
          }
          .comment-is-empty{
            color: rgba(165, 173, 185, 1);

          }
        }
      }
    }
  }
}




@media (max-width: 880px) {
  .page-wrapper{
    padding: 37px 16px 0 16px;
  }
  .withdraw-page {
    display: block;
    flex-direction: column;
    width: 100%;
    gap: 0;



    .tables-container{
      background: none;
      .table{

        display: none;
      }
    }





    h2 {
      margin-bottom: 24px;
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .table-row {
    margin-bottom: 24px;

    padding: 20px;
    border-radius: 12px;
    border: 1px rgba(209, 213, 219, 1) solid;
    display: flex;
    flex-direction: column;

  }
  .table-flex{
    display: flex;
    justify-content: space-between;

    &>*{
      flex-basis: 50%;
      align-self: flex-end;
      justify-self: flex-end;
    }


  }
  .align{
    align-items: start;

  }
  .center-card{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .table-cell {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: black;
    gap: 16px;
    padding-bottom:24px ;





    &.title {
      font-weight: 600;
      color: rgba(255, 255, 255, 0.7);


    }
  }

  .payment-system {
    display: flex;
    align-items: start;
    gap: 8px;

    img {
      height: 24px;
      width: 24px;
    }
  }

  .status {
    &.success, &.fail {
      color: inherit;
    }
  }

  .comment {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;

    &.comment-is-empty {
      color: rgba(255, 255, 255, 0.7);
    }
  }


  .dark .withdraw-wrapper .tables-container{
    background: #060819;
    padding: 0 ;
    .table-row{
      background: linear-gradient(90deg, #0F101D 0%, #1B2133 100.03%);
      border: 1px solid rgba(32, 37, 54, 1);
      .title{
        color: white;
      }
      .gray{
        color: rgba(157, 157, 161, 1);
      }
    }
  }






}
@media (max-width: 880px) {
  .withdraw-page {
    .table {

    }

    .raw-data {
      display: block;
    }
  }
}
